import { template as template_f9e52738be744426b4224f7d4ff88fc7 } from "@ember/template-compiler";
const FKControlMenuContainer = template_f9e52738be744426b4224f7d4ff88fc7(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
