import { template as template_cd46e25f6fce4b8e9ecb4502316541b4 } from "@ember/template-compiler";
const WelcomeHeader = template_cd46e25f6fce4b8e9ecb4502316541b4(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
